.Menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 320px;
    height: 100%;
    background: #3F414E;
    color: #7F848C;
}
.Menu__profile {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #7F848C;
    padding: 20px 30px;
}
.Menu__profile div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #6793DB;
    color: #FFFFFF;
    margin-right: 1rem;
}
.Menu__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 30px 0 0 30px;
}
.Menu__item {
    width: 100%;
    margin-bottom: 26px;
}
.Menu__link {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-decoration: none;
    color: #7F848C;
}
.Menu__link_active, 
.Menu__link a:hover {
    color: #FFFFFF;
}

@media screen and (max-width: 414px) {
    .Menu {
        left: -320px;
        transition: left 0.3s ease-in-out;
    }
    .Menu.show {
        left: 0;
        transition: left 0.3s ease-in-out;
    }
}