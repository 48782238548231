.CardBlogerItem {
    min-width: 260px;
    max-width: 260px;
    max-height: 260px;
    text-align: center;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    padding: 20px 30px 30px;
}
.CardBlogerItem__avatar {
    width: 80px;
    height: 80px;
    margin: 0 auto 1rem;
}
.CardBlogerItem__avatar img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
.CardBlogerItem__name {
    padding-bottom: 1rem;
    font-size: 1rem;
    color: #000000;
}
.CardBlogerItem__name p {
    max-height: 38px;
    min-height: 38px;
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.CardBlogerItem__link {
    padding-bottom: 10px;
}
.CardBlogerItem__link a {
    color: #6793DB;
    cursor: pointer;
    text-decoration: none;
    max-height: 36px;
    min-height: 36px;
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.CardBlogerItem__solution {
    display: flex;
    align-items: center;
    justify-content: center;
}
.CardBlogerItem__solution button {
    padding: 5px 17px;
    min-width: 94px;
    max-width: 94px;
    height: 25px;
    background: #E4E6EA;
    color: #3F414E;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}
.CardBlogerItem__solution button:first-child {
    color: #FFFFFF;
    background: #6793DB;
    margin-right: 0.5rem;
}

@media screen and (max-width: 414px) {
    .CardBlogerItem {
        min-width: calc((100vw - 3rem) / 2);
        max-width: 156px;
        max-height: 163px;
        padding: 16px 20px 8px;
    }
    .CardBlogerItem__avatar {
        margin-bottom: 0.5rem;
    }
    .CardBlogerItem__avatar,
    .CardBlogerItem__avatar img {
        width: 40px;
        height: 40px;
    }
    .CardBlogerItem__solution button {
        height: 20px;
        min-width: calc(((100vw - 3rem) / 4) - 0.875rem);
        max-width: 70px;
        font-size: 10px;
        padding: 5px 8px;
    }
    .CardBlogerItem__solution button:first-child {
        margin-right: 0.4rem;
    }
    .CardBlogerItem__name {
        font-size: 12px;
        padding-bottom: 0.5rem;
    }
    .CardBlogerItem__name p {
        max-height: 28px;
        min-height: 28px;
    }
    .CardBlogerItem__link {
        font-size: 10px;
        padding-bottom: 0.5rem;
    }
    .CardBlogerItem__link a {
        max-height: 26px;
        min-height: 26px;
    }
}