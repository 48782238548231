.Preloader {
    background-image: url('../../img/Spin-100px.svg');
    height: 100px;
    width: 100px;
}

@media screen and (max-width: 414px) {
    .Preloader {
        background-color: #F2F3F7;
    }
}