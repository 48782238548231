.BurgerMenu {
    cursor: pointer;
    width: 2.5rem;
    height: 3rem;
    z-index: 101;
    display: none;
}
.BurgerMenu div {
    position: relative;
    width: 3rem;
    height: 3px;
    background: #3F414E;
}
.BurgerMenu div::after, .BurgerMenu div::before {
    content: '';
    position: absolute;
    width: 1.625rem;
    height: 3px;
    background: #3F414E;
    top: 9px;
    right: 0;
    transition: 0.5s ease;
}

.BurgerMenu div::before {
    top: -9px;
}

.BurgerMenu:hover div::after {
    width: 2.5rem;
}
.BurgerMenu.open div {
    transform: rotate(45deg);
    background: #333333;
}
.BurgerMenu.open div::after {
    top: 0;
    width: 2.5rem;
    background: #333333;
    transform: rotate(-90deg);
}
@media screen and (max-width: 414px) {
    .BurgerMenu {
        display: flex;
        width: 1.75rem;
        margin: 0;
        align-items: center;
    }
    .BurgerMenu div {
        width: 1.75rem;
        height: 1.5px;
        border-radius: 1rem;
        transition: left 0.5s ease-in-out;
    }
    .BurgerMenu div::after, .BurgerMenu div::before {
        width: 1.75rem;
        height: 1.5px;
        border-radius: 1rem;
    }
    .BurgerMenu:hover div::after {
        width: 1.75rem;
    }
    .BurgerMenu.open {
        position: relative;
        left: 16rem;
        transition: left 0.5s ease-in-out;
    }
    .BurgerMenu.open div {
        background-color: #7F848C;
    }
    .BurgerMenu.open div::after {
        top: 0;
        width: 1.75rem;
        background: #7F848C;
        transform: rotate(-90deg);
    }
    .BurgerMenu.open div::before {
        display: none;
    }
}