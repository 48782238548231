.AuthorizationForm {
    min-height: 485px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
    padding: 48px 104px 104px;
}
.AuthorizationForm__img {
    width: 45px;
    height: 45px;
    margin: 0 auto 1.5rem;
}
.AuthorizationForm__img img {
    width: 100%;
    height: 100%;
}
.AuthorizationForm__title,
.AuthorizationForm__subtitle {
    text-align: center;
    color: #3F414E;
}
.AuthorizationForm__title {
    font-family: 'Roboto-Medium';
    font-weight: 500;
    font-size: 1.125rem;
    margin-bottom: 1rem;
}
.AuthorizationForm__subtitle {
    font-size: 12px;
    margin-bottom: 1.5rem;
}
.AuthorizationForm__input {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}
.AuthorizationForm__input,
.AuthorizationForm button,
.AuthorizationForm__form input[type=submit] {
    width: 100%;
    min-width: 360px;
    height: 44px;   
    border: 1px solid #E8E8E8;
    border-left: none;
    border-radius: 0.5rem;
    margin-bottom: 1.25rem;
}
.AuthorizationForm__input div {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 45px;
    min-height: 44px;
    padding: 10px;
    margin-right: 1.5rem;
    background: #6793DB;
    border-radius: 4px;
}
.AuthorizationForm__input input {
    width: 100%;
    border: none;
    color: #98999A;
    font-size: 12px;
}
.AuthorizationForm__form input[type=submit] {
    font-family: 'Roboto-Medium';
    font-size: 1.125rem;
    margin-top: 0.625rem;
    background: #6793DB;
    height: 3.125rem; 
    color: #FFFFFF;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
}
.AuthorizationForm__form input[type=submit]:disabled { 
    background: #ccc; 
}
.AuthorizationForm__input.errorMessage {
    border-color: #FF3748;
}
.AuthorizationForm__errorMessage {
    color: #FF3748;
    font-size: 0.75rem;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .AuthorizationForm__input,
    .AuthorizationForm__form input[type=submit] {
        min-width: 100%;
    }
}

@media screen and (max-width: 414px) {
    .AuthorizationForm {
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 48px 1rem 104px;
    }
    .AuthorizationForm__img {
        margin-bottom: 5rem;
    }
    .AuthorizationForm__form input[type=submit] {
        margin-top: 0;
    }
    .AuthorizationForm__subtitle {
        color: #98999A;
    }
    .AuthorizationForm__title {
        margin-bottom: 22px;
    }
}
