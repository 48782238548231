.NewBlogers {
    padding: 1rem;
    margin-left: 320px;
    overflow-x: hidden;
}
.NewBlogers__title {
    font-family: 'Roboto-Bold';
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    color: #212121;
    margin-bottom: 2.5rem;
}
.NewBlogers__spinner {
    margin: 100px auto;
    display: flex;
    justify-content: center;
}
.NewBlogers__header {
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}
.NewBlogers__count {
    display: none;
}

@media screen and (max-width: 414px) {
    .NewBlogers {
        margin-left: 0;
        padding-top: 0;
        height: 100vh;
    }
    .NewBlogers__header {
        display: flex;
    }
    .NewBlogers__title {
        font-size: 18px;
        margin-bottom: 0.5rem;
    }
    .NewBlogers__count {
        display: block;
        text-align: end;
        font-size: 1rem;
        margin-bottom: 1rem;
    }
}
