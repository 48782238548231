
/** Roboto **/

@font-face {
	font-family: 'Roboto';
	src: url('./fonts/Roboto/Roboto-Regular.eot');
	src: local('☺'), url('./fonts/Roboto/Roboto-Regular.woff') format('woff'), url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype'), url('./fonts/Roboto/Roboto-Regular.svg') format('svg');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Roboto-Medium';
	src: url('./fonts/Roboto/Roboto-Medium.eot');
	src: local('☺'), url('./fonts/Roboto/Roboto-Medium.woff') format('woff'), url('./fonts/Roboto/Roboto-Medium.ttf') format('truetype'), url('./fonts/Roboto/Roboto-Medium.svg') format('svg');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Roboto-Bold';
	src: url('./fonts/Roboto/Roboto-Bold.eot');
	src: local('☺'), url('./fonts/Roboto/Roboto-Bold.woff') format('woff'), url('./fonts/Roboto/Roboto-Bold.ttf') format('truetype'), url('./fonts/Roboto/Roboto-Bold.svg') format('svg');
	font-weight: 700;
	font-style: normal;
}



body, input {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #444444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*, ::before, ::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
input:focus-visible {
  outline: none;
}
ul, ol {
  list-style: none;
}

@media screen and (max-width: 414px) {
	.App {
		background-color: #F2F3F7;
		max-height: 100vh;
		overflow: hidden;
	}
}
